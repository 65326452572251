import bilAPI from '../api';

export const getExamTasks = ({ exam_type, exam_collection }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/exams/celery_exam_task/`, {
          params: { exam_type, exam_collection },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getExamTask = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/exams/celery_exam_task/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

// export const deleteExam = (uuid) => {
//   return new Promise((resolve, reject) => {
//     try {
//       bilAPI
//         .delete(`/exams/exams/${uuid}`)
//         .then((res) => {
//           resolve(res);
//         })
//         .catch((err) => {
//           reject("axios error");
//         });
//     } catch (error) {
//       reject("system error");
//     }
//   });
// };

export const postExamTask = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/exams/celery_exam_task/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

// export const putExam = (uuid, body) => {
//   return new Promise((resolve, reject) => {
//     try {
//       bilAPI
//         .put(`/exams/exams/${uuid}/`, body)
//         .then((res) => {
//           resolve(res.data);
//         })
//         .catch((err) => {
//           reject("axios error");
//         });
//     } catch (error) {
//       reject("system error");
//     }
//   });
// };
